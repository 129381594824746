import React from 'react';
import { Category } from "components"
import { graphql } from 'gatsby';


export default function CategoryTemplate(props) {
  return <Category {...props} />
}

export const pageQuery = graphql`
  query CategoryById($id: String!) {
    sitePage(context: {id: {eq: $id}}) {
      context {
        description
        id
        title
      }
    }
  }
`;
